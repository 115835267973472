import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { ProcessContent } from "./process"

export default function WorkWithMe() {
  return (
    <Layout>
      <React.Fragment>
        <section className="flex justify-content-center-space-between align-items-center halves m-y-3">
          <img src="/assets/images/work-with-me.1.jpg"></img>

          <section className="bordered left p-2">
            <h1 className="m-b-2">WORK WITH ME</h1>

            <h3>Are you looking for a skilled, reliable, and passionate writer to share your message of health and wellness with the world?</h3>
            <p>
              As a freelance health and wellness writer, I can help with everything from blogging and ghostwriting to creating written content for your landing pages, printed materials, and other company documents. And if you need a proofreader with a strong attention to detail, I can take that off your plate as well.
            </p>

            <h3 className="m-t-2">Let's Work Together</h3>
            <p>
              To learn more about the <Link to="/services-and-rates">writing and proofreading services</Link> I offer — or to get the ball rolling — please <Link to="/contact">contact me</Link>. I’m currently accepting new clients and eager to share your message with the world!
            </p>
          </section>
        </section>
        <ProcessContent></ProcessContent>
      </React.Fragment>
    </Layout>
  )
}


